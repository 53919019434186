const DOMAIN = "admin.corneliacontactalgeria.com";
const LINK = `https://${DOMAIN}`;

//const API_DOMAIN = process.env.REACT_APP_DOMAIN;
const API_DOMAIN = "api-dev.corneliacontactalgerie.com";
const UPLOADS_DOMAIN = `https://${API_DOMAIN}`;

// const API_DOMAIN = 'cornelia-express.herokuapp.com';
const APP_LINK = `https://${API_DOMAIN}`;
const API_LINK = `${APP_LINK}/api`;
const ERP_LINK = "https://cornelia-backend-test.ipconnex.com/api";

const config = {
	environment: process.env.REACT_APP_ENV,

	login: {
		email: process.env.REACT_APP_EMAIL,
		password: process.env.REACT_APP_PASSWORD,
	},

	filters: {
		dates: {
			start: -3,
			end: 0,
		},
	},
	sentry: {
		release: process.env.REACT_APP_VERSION,
		debug: true,
		dsn: process.env.REACT_APP_SENTRY_DSN,
	},

	domain: DOMAIN,
	user: `cornelia-user-${DOMAIN}`,
	link: LINK,
	uploads: UPLOADS_DOMAIN,

	links: {
		conditions:
			"https://www.corneliacontactalgerie.com/conditions.html",
		contact:
			"https://www.corneliacontactalgerie.com/contact.html?email=",
		youtube: "",
	},
	routes: {
		auth: {
			login: `${API_LINK}/auth/login`,
		},
		orders: {
			fetch: `${API_LINK}/orders/`,
			check: `${API_LINK}/orders/check-order`,
			create: `${API_LINK}/orders/`,
			patch: `${API_LINK}/orders/`,
			export: `${API_LINK}/exports`,
		},
		clients: {
			get_all: `${API_LINK}/users/all`,
			// create: `http://localhost:5000`,
			create: `${API_LINK}/users`,
			patch: `${API_LINK}/users`,
			delete: `${API_LINK}/users`,
			upload: `${API_LINK}/users/upload`,
			getMedia: `${API_LINK}/users/getMedia`,
			resetPassword: `${API_LINK}/auth/reset-password`,
			changePassword: `${API_LINK}/auth/change-password`,
			invite: `${API_LINK}/auth/invite-client`,
		},
	},
	status: {
		INIT: "Commandé",
		VALIDATED: "Validé",
		MANIFACTURING: "En fabrication",
		DELIVERING: "En livraison",
		DELIVERED: "Livré",
		CANCELED: "Annulé",
		REJECTED: "Rejeté",
		RETURN: "Retour",
		RETURN_CONFIRMED: "Retour confirmé",
	},

	erp: {
		environment: process.env.REACT_APP_ENV,
		token: "token d7d44c547ccc89f:87ac018060e6b0b",

		links: {
			conditions:
				"https://www.corneliacontactalgerie.com/conditions.html",
			contact:
				"https://www.corneliacontactalgerie.com/contact.html?email=",
			youtube: "",
		},
		routes: {
			auth: {
				login: `${ERP_LINK}/auth/login`,
			},
			products: {
				fetch: `${ERP_LINK}/resource/Item`,
			},
			orders: {
				fetch: `${ERP_LINK}/resource/order/`,
				check: `${ERP_LINK}/orders/check-order`,
				create: `${ERP_LINK}/orders/`,
				patch: `${ERP_LINK}/orders/`,
				export: `${ERP_LINK}/exports`,
			},
			clients: {
				get_all: `${ERP_LINK}/users/all`,
				// create: `http://localhost:5000`,
				create: `${ERP_LINK}/users`,
				patch: `${ERP_LINK}/users`,
				delete: `${ERP_LINK}/users`,
				upload: `${ERP_LINK}/users/upload`,
				getMedia: `${ERP_LINK}/users/getMedia`,
				resetPassword: `${ERP_LINK}/auth/reset-password`,
				changePassword: `${ERP_LINK}/auth/change-password`,
				invite: `${ERP_LINK}/auth/invite-client`,
			},
		},
		status: {
			INIT: "Commandé",
			VALIDATED: "Validé",
			MANIFACTURING: "En fabrication",
			DELIVERING: "En livraison",
			DELIVERED: "Livré",
			CANCELED: "Annulé",
			REJECTED: "Rejeté",
			RETURN: "Retour",
			RETURN_CONFIRMED: "Retour confirmé",
		},
	},
};

export default config;
